import type { OfferInfo } from '@patrianna-payments/shared-store/payments/types/openApiModels/OfferInfo'

/**
 * sorts by priority from lowest to highest </br>
 * 0 - is the highest </br>
 * @example [5, 3, 1, 0]
 * @param offers
 */
export const sortByPriority = (offers: OfferInfo[]) =>
  [...offers].sort((itemA: OfferInfo, itemB: OfferInfo) => itemB.priority - itemA.priority)

/**
 * @return sorted offers by price from lowest to highest
 * @param offers
 */
export const sortByPrice = (offers: OfferInfo[]) =>
  [...offers].sort((itemA: OfferInfo, itemB: OfferInfo) => itemA.price - itemB.price)

export const getSpecialOffers = (offers: OfferInfo[]) => offers.filter(isSpecialOffer)
export const hasSpecialOffers = (offers: OfferInfo[]) => offers.some(isSpecialOffer)

export const getHighestPrioritySpecialOffer = (offers: OfferInfo[]) => {
  const specialOffers = getSpecialOffers(offers)
  const sortedByPriority = sortByPriority(specialOffers)

  if (sortedByPriority.length > 0) {
    return sortedByPriority[sortedByPriority.length - 1]
  }

  return null
}

/**
 * @returns all the highest priority offers
 * @param offers
 */
export const getHighestPriorityOffers = (offers: OfferInfo[]) => {
  if (!offers?.length) {
    return []
  }

  const priorityToOfferMap: Map<number, OfferInfo[]> = new Map()
  const prioritiesSet: Set<number> = new Set()

  for (const offer of offers) {
    prioritiesSet.add(offer.priority)

    if (!priorityToOfferMap.has(offer.priority)) {
      priorityToOfferMap.set(offer.priority, [])
    }

    priorityToOfferMap.set(offer.priority, [...priorityToOfferMap.get(offer.priority), offer])
  }

  const sortedPriorities = Array.from(prioritiesSet).sort()

  return priorityToOfferMap.get(sortedPriorities[0])
}

export const isSpecialOffer = (offer: OfferInfo) => offer.tags.includes('special_offer')
export const isRegularOffer = (offer: OfferInfo) => !offer.tags.includes('special_offer')

/**
 * @returns the highest priority number or -1 if not found
 * @param offers
 */
export const getHighestPriority = (offers: OfferInfo[]) => {
  if (!offers?.length) {
    return -1
  }

  let highestPriority = offers[0].priority

  for (const offer of offers) {
    if (offer.priority < highestPriority) {
      highestPriority = offer.priority
    }
  }

  return highestPriority
}

export const getRegularOffersForQuickPurchase = (
  availableOffers: OfferInfo[],
  lastPurchasedOffer?: OfferInfo
): OfferInfo[] => {
  const sortedOffers = sortByPrice(availableOffers)
  let slot1: OfferInfo
  let slot2: OfferInfo

  const lastPurchaseIndex = sortedOffers.findIndex((item) => item.code === lastPurchasedOffer?.code)
  const isLastPurchaseAvailable = lastPurchaseIndex > -1
  const isLastPurchaseHighestPriced = lastPurchasedOffer?.price >= sortedOffers[sortedOffers.length - 1]?.price

  if (isLastPurchaseAvailable) {
    if (isLastPurchaseHighestPriced) {
      slot1 = sortedOffers[lastPurchaseIndex - 1]
      slot2 = sortedOffers[lastPurchaseIndex]
    } else {
      slot1 = sortedOffers[lastPurchaseIndex]
      slot2 = sortedOffers[lastPurchaseIndex + 1]
    }
  } else {
    if (isLastPurchaseHighestPriced) {
      slot1 = sortedOffers[sortedOffers.length - 2]
      slot2 = sortedOffers[sortedOffers.length - 1]
    } else {
      const offerWithSameOrHigherPriceIndex = sortedOffers.findIndex(
        (offer) => offer.price >= lastPurchasedOffer?.price
      )

      if (offerWithSameOrHigherPriceIndex > -1) {
        if (sortedOffers[offerWithSameOrHigherPriceIndex + 1]) {
          slot1 = sortedOffers[offerWithSameOrHigherPriceIndex]
          slot2 = sortedOffers[offerWithSameOrHigherPriceIndex + 1]
        } else {
          slot1 = sortedOffers[offerWithSameOrHigherPriceIndex - 1]
          slot2 = sortedOffers[offerWithSameOrHigherPriceIndex]
        }
      }
    }
  }

  return [slot1, slot2].filter(Boolean)
}

export const getSpecialOffersForQuickPurchase = (offers: OfferInfo[], lastPurchasedOffer?: OfferInfo) => {
  const getOfferForSlot1 = () => {
    const lastPurchaseIndex = offers.findIndex((offer) => offer.code === lastPurchasedOffer?.code)

    if (lastPurchaseIndex > -1) {
      return offers[lastPurchaseIndex]
    } else {
      const regularOfferWithTheSamePriceAsLastPurchase = offers
        .filter(isRegularOffer)
        .find((regularOffer) => regularOffer.price === lastPurchasedOffer?.price)

      if (regularOfferWithTheSamePriceAsLastPurchase) {
        return regularOfferWithTheSamePriceAsLastPurchase
      } else {
        const regularOfferWithTheHigherPriceAsLastPurchase = sortByPrice(offers.filter(isRegularOffer)).find(
          (regularOffer) => regularOffer.price > lastPurchasedOffer?.price
        )

        if (regularOfferWithTheHigherPriceAsLastPurchase) {
          return regularOfferWithTheHigherPriceAsLastPurchase
        } else {
          const sortedByPrice = sortByPrice(offers.filter(isRegularOffer))

          return sortedByPrice[sortedByPrice.length - 1]
        }
      }
    }
  }

  const offerForSlot1 = getOfferForSlot1()

  const getOfferForSlot2 = () => {
    const specialOffers = getSpecialOffers(offers)
    const highestPrioritySpecialOffers = getHighestPriorityOffers(specialOffers)
    const sortedByPrice = sortByPrice(highestPrioritySpecialOffers)

    let index = sortedByPrice.length - 1

    while (offerForSlot1?.code === sortedByPrice[index]?.code && index >= 0) {
      index--
    }

    return sortedByPrice[index]
  }

  const offerForSlot2 = getOfferForSlot2()

  return [offerForSlot1, offerForSlot2].filter(Boolean)
}

export const getHighestPriorityOffersSortedByPrice = (offers: OfferInfo[]) => {
  if (!offers?.length) {
    return []
  }

  const priorityToOfferMap: Map<number, OfferInfo[]> = new Map()
  const prioritiesSet: Set<number> = new Set()

  for (const offer of offers) {
    prioritiesSet.add(offer.priority)

    if (!priorityToOfferMap.has(offer.priority)) {
      priorityToOfferMap.set(offer.priority, [])
    }

    priorityToOfferMap.set(offer.priority, [...priorityToOfferMap.get(offer.priority), offer])
  }

  const sortedPriorities = Array.from(prioritiesSet).sort((a, b) => b - a)
  const priorityArrays = sortedPriorities.map((priority) => sortByPrice(priorityToOfferMap.get(priority)))

  return priorityArrays.flat()
}

export const getHighestPriorityMostExpensiveSpecialOffer = (offers: OfferInfo[], excludeCodes: string[]) => {
  const highestPriorityOffers = getHighestPriorityOffersSortedByPrice(getSpecialOffers(offers))

  let index = highestPriorityOffers.length - 1

  while (excludeCodes?.includes(highestPriorityOffers[index]?.code) && index >= 0) {
    index--
  }

  return highestPriorityOffers[index]
}
