export const SPECIAL_NEW_YEAR_OFFER_560 = '590kgc_291sc_280_99_23dec'
export const SPECIAL_NEW_YEAR_OFFER_296 = '296kgc_145sc_140_99_23dec'
export const SPECIAL_NEW_YEAR_OFFER_149 = '149kgc_73sc_70_99_23dec'

export const SPECIAL_NEW_YEAR_OFFER_480 = '480kgc241sc199_99_24dec'
export const SPECIAL_NEW_YEAR_OFFER_236 = '236kgc120sc99_99_24de'
export const SPECIAL_NEW_YEAR_OFFER_115 = '115kgc58_5sc49_99_24dec'

export const SPECIAL_OFFER_94500GC46_8SC44_99_6FEB25 = '94500gc46_8sc44_99_6feb25'
export const SPECIAL_OFFER_167KGC83SC79_99_6FEB25 = '167kgc83sc79_99_6feb25'
export const SPECIAL_OFFER_315KGC156SC149_99_6FEB25 = '315kgc156sc149_99_6feb25'
export const SPECIAL_OFFER_79400GC35_5SC34_99_6FEB25_AML = '79400gc35_5sc34_99_6feb25_aml'
export const SPECIAL_OFFER_149KGC74SC70_99_6FEB25 = '149kgc74sc70_99_6feb25'
export const SPECIAL_OFFER_296KGC147SC140_99_6FEB25 = '296kgc147sc140_99_6feb25'
export const SPECIAL_OFFER_590KGC293SC280_99_6FEB25 = '590kgc293sc280_99_6feb25'
export const SPECIAL_OFFER_136500GC66SC64_99_6FEB25_AML = '136500gc66sc64_99_6feb25_aml'
export const SPECIAL_OFFER_170KGC89SC79_99_6FEB25 = '170kgc89sc79_99_6feb25'
export const SPECIAL_OFFER_315KGC167SC149_99_6FEB25 = '315kgc167sc149_99_6feb25'
export const SPECIAL_OFFER_610KGC323SC289_99_6FEB25 = '610kgc323sc289_99_6feb25'
export const SPECIAL_OFFER_147KGC72SC69_99_6FEB25_AML = '147kgc72sc69_99_6feb25_aml'
export const SPECIAL_OFFER_94500GC46_8SC44_99_7FEB25 = '94500gc46_8sc44_99_7feb25'
export const SPECIAL_OFFER_167KGC83SC79_99_7FEB25 = '167kgc83sc79_99_7feb25'
export const SPECIAL_OFFER_315KGC156SC149_99_7FEB25 = '315kgc156sc149_99_7feb25'
export const SPECIAL_OFFER_79400GC35_5SC34_99_7FEB25_AML = '79400gc35_5sc34_99_7feb25_aml'
export const SPECIAL_OFFER_149KGC74SC70_99_7FEB25 = '149kgc74sc70_99_7feb25'
export const SPECIAL_OFFER_296KGC147SC140_99_7FEB25 = '296kgc147sc140_99_7feb25'
export const SPECIAL_OFFER_590KGC293SC280_99_7FEB25 = '590kgc293sc280_99_7feb25'
export const SPECIAL_OFFER_136500GC66SC64_99_7FEB25_AML = '136500gc66sc64_99_7feb25_aml'
export const SPECIAL_OFFER_170KGC89SC79_99_7FEB25 = '170kgc89sc79_99_7feb25'
export const SPECIAL_OFFER_315KGC167SC149_99_7FEB25 = '315kgc167sc149_99_7feb25'
export const SPECIAL_OFFER_610KGC323SC289_99_7FEB25 = '610kgc323sc289_99_7feb25'
export const SPECIAL_OFFER_147KGC72SC69_99_7FEB25_AML = '147kgc72sc69_99_7feb25_aml'
